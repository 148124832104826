import { createReducer, on } from '@ngrx/store';
import { loadersActions } from '../actions/loaders.actions';

export interface LoadersState {
    ids: string[];
}

export const initialState: LoadersState = {
    ids: [],
};

export const loadersReducer = createReducer(
    initialState,
    on(
        loadersActions.add,
        (state, action): LoadersState => ({
            ...state,
            ids: [...state.ids, action.id],
        }),
    ),
    on(loadersActions.delete, (state, action): LoadersState => {
        const indexId = state.ids.indexOf(action.id);
        const ids = [...state.ids];
        ids.splice(indexId, 1);
        return {
            ...state,
            ids,
        };
    }),
);
