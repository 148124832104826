import { Injectable, inject } from '@angular/core';
import { AccountFacade } from '@corelabs/angular-account';
import { createEffect } from '@ngrx/effects';
import { loadersActions } from '../actions/loaders.actions';
import { map } from 'rxjs';

@Injectable()
export class LoadersEffects {
    private accountFacade = inject(AccountFacade);

    accountFacadeSaving$ = createEffect(() => {
        return this.accountFacade.saving$.pipe(
            map((saving) => {
                if (saving === true) {
                    return loadersActions.add({ id: 'account' });
                }
                return loadersActions.delete({ id: 'account' });
            }),
        );
    });
}
