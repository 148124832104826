import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectLoading } from '../selectors/loaders.selector';
import { loadersActions } from '../actions/loaders.actions';

@Injectable({
    providedIn: 'root',
})
export class LoadersFacade {
    private store = inject(Store);

    loading$ = this.store.select(selectLoading);

    add(id: string): void {
        this.store.dispatch(loadersActions.add({ id }));
    }

    delete(id: string): void {
        this.store.dispatch(loadersActions.delete({ id }));
    }
}
