import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LOADERS_FEATURE_KEY } from './keys';
import { loadersReducer } from './reducers/loaders.reducer';
import { LoadersEffects } from './effects/loaders.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(LOADERS_FEATURE_KEY, loadersReducer), EffectsModule.forFeature([LoadersEffects])],
})
export class LoadersStoreModule {}
